import * as React from 'react';
import { Engine } from '@websky/core';
import { ThemeProvider } from '@websky/core/src/theme';
import theme from '../../theme/theme';
import '../../theme/variables.css';
import Head from '@websky/core/src/Head';
import { RenderersProvider } from '@websky/core/src/renderProps';
import { renders } from '../../renders';
var EngineComponent = function () {
    return (React.createElement(RenderersProvider, { value: renders },
        React.createElement(ThemeProvider, { value: theme },
            React.createElement(Head, null),
            React.createElement(Engine, null))));
};
export default EngineComponent;
