import * as React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { useTheme } from '../../theme';
import CheckoutAdditionalServiceBase from '../CheckoutAdditionalService/CheckoutAdditionalService';
import { BaseButton } from '../../BaseComponents';
import Money from '../../Money';
const CheckoutAdditionalServiceV2 = props => {
    const { t } = useTranslation('CheckoutAdditionalService');
    const { CheckoutAdditionalService: theme, CheckoutAdditionalServiceV2: themeV2 } = useTheme('CheckoutAdditionalService');
    const renderControls = React.useCallback(({ buttons, addButtonText, showAddButton, isSelected, isLoading, addClick }) => (React.createElement("div", { className: theme.buttons },
        (props.priceTotal || props.priceFrom) && (React.createElement("div", { className: themeV2.price },
            isSelected && props.priceTotal && (React.createElement(React.Fragment, null,
                t('Checkout:Total'),
                ": ",
                React.createElement(Money, { money: props.priceTotal }))),
            !isSelected && props.priceFrom && (React.createElement(React.Fragment, null,
                t('from'),
                " ",
                React.createElement(Money, { money: props.priceFrom }))))),
        isSelected &&
            (buttons === null || buttons === void 0 ? void 0 : buttons.map(button => (React.createElement(BaseButton, { key: button.text, className: cn(themeV2.button, { [themeV2.edit]: button.buttonType === 'change' }), isLoading: button.isLoading, onClick: () => button.action(), variant: "optionEdit" }, button.text)))),
        !isSelected && showAddButton !== false && (React.createElement(BaseButton, { isLoading: isLoading, variant: "optionAction", className: themeV2.button, onClick: addClick }, addButtonText ? t(addButtonText) : t('Add'))))), [props.priceFrom, props.priceTotal]);
    return React.createElement(CheckoutAdditionalServiceBase, Object.assign({}, props, { renderControls: renderControls }));
};
export default { CheckoutAdditionalService: CheckoutAdditionalServiceV2 };
