import { CardType } from '@websky/core/src/PromoLoader/types';
import { Locale } from '@websky/core/src/types';
import { getLocale } from '@websky/core/src/utils';
import { OauthServiceType } from '@websky/graphql';
import { Sorting } from '@websky/core/src/Results/enums';
const currentLocale = getLocale();
const isProd = process.env.NODE_ENV === 'production';
currentLocale;
isProd;
export const passengersConfig = [
    {
        code: 'ААА',
        parentCode: 'ADT',
        isAdult: true,
        noAvailableWith: ['МЛА', 'ЦПА', 'ЦПА'],
        defaultCount: 1
    },
    {
        code: 'РБГ',
        parentCode: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 0
    },
    {
        code: 'РМГ',
        parentCode: 'INF',
        isAdult: false,
        noAvailableWith: ['ЦПА', 'МЛА'],
        maxCountWithoutAdult: 0
    },
    {
        code: 'РВГ',
        parentCode: 'INS',
        noAvailableWith: ['ЦПА', 'МЛА'],
        isAdult: false,
        maxCountWithoutAdult: 0
    },
    {
        code: 'МЛА',
        parentCode: 'ADT',
        isAdult: true,
        noAvailableWith: ['ЦПА', 'ААА', 'ЦПА', 'РМГ', 'РВГ'],
        group: 'youngAdults',
        defaultCount: 1
    },
    {
        code: 'РБГ',
        parentCode: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 0,
        group: 'youngAdults'
    },
    {
        code: 'ЦПА',
        parentCode: 'ADT',
        isAdult: true,
        noAvailableWith: ['ААА', 'МЛА'],
        defaultCount: 1,
        group: 'pension'
    },
    {
        code: 'РБГ',
        parentCode: 'CLD',
        isAdult: false,
        maxCountWithoutAdult: 0,
        group: 'pension'
    }
    // {
    // 	code: 'ААА', // взрослые
    // 	parentCode: 'ADT',
    // 	isAdult: true,
    // 	defaultCount: 1,
    // 	noAvailableWith: ['МЛА', 'ЦПА', 'ЦПА'],
    // 	group: 'severstalLoyaltyDobroPozhalovat'
    // },
    // {
    // 	code: 'РБГ', // дети
    // 	parentCode: 'CLD',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalLoyaltyDobroPozhalovat'
    // },
    // {
    // 	code: 'РВГ', // младенцы с местом
    // 	parentCode: 'INS',
    // 	noAvailableWith: ['МЛА'],
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalLoyaltyDobroPozhalovat'
    // },
    // {
    // 	code: 'РМГ', // младенцы
    // 	parentCode: 'INF',
    // 	isAdult: false,
    // 	noAvailableWith: ['МЛА'],
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalLoyaltyDobroPozhalovat'
    // },
    // {
    // 	code: 'PFA', // взрослые
    // 	parentCode: 'ADT',
    // 	isAdult: true,
    // 	group: 'severstalFamily',
    // 	maxCount: 2,
    // 	defaultCount: 2,
    // 	modifyCount: 2
    // },
    // {
    // 	code: 'РБГ', // дети
    // 	parentCode: 'CLD',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalFamily'
    // },
    // {
    // 	code: 'РВГ', // младенцы с местом
    // 	parentCode: 'INS',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalFamily'
    // },
    // {
    // 	code: 'РМГ', // младенцы
    // 	parentCode: 'INF',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalFamily'
    // },
    // {
    // 	code: 'PDA', // взрослые
    // 	parentCode: 'ADT',
    // 	isAdult: true,
    // 	group: 'severstalMotherAndChild',
    // 	maxCount: 1,
    // 	defaultCount: 1
    // },
    // {
    // 	code: 'РБГ', // дети
    // 	parentCode: 'CLD',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalMotherAndChild'
    // },
    // {
    // 	code: 'РВГ', // младенцы с местом
    // 	parentCode: 'INS',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalMotherAndChild'
    // },
    // {
    // 	code: 'РМГ', // младенцы
    // 	parentCode: 'INF',
    // 	isAdult: false,
    // 	maxCountWithoutAdult: 0,
    // 	group: 'severstalMotherAndChild'
    // },
    //
];
let privacyPolicy;
switch (currentLocale) {
    case Locale.English:
        privacyPolicy = `https://www.severstal-avia.ru/en/info/confidentiality/privacy-notice-short-version.php`;
        break;
    default:
        privacyPolicy = `https://www.severstal-avia.ru/info/konfidentsialnost/politika-konfidentsialnosti.php`;
}
const extractCurrentStep = (url) => {
    const terms = ['exchange', 'refund'];
    for (const term of terms) {
        if (url.includes(term)) {
            return term;
        }
    }
    return null;
};
const localeLinkFactory = (path) => {
    let resultPath = path;
    if (!path.startsWith('/')) {
        resultPath = '/' + path;
    }
    if (currentLocale === Locale.Russian) {
        return `https://www.severstal-avia.ru${resultPath}`;
    }
    else {
        return `https://www.severstal-avia.ru/${currentLocale}${resultPath}`;
    }
};
let exareRulesURL;
switch (extractCurrentStep(window.location.href)) {
    case 'exchange':
        exareRulesURL = localeLinkFactory('/vash-polyet/izmeneniya-v-bilete.php');
        break;
    case 'refund':
        exareRulesURL = localeLinkFactory('/vash-polyet/vernut-bilet.php');
        break;
    default:
        exareRulesURL = ``;
}
export const companyInfo = {
    iataCode: 'D2',
    loyaltyProgramName: 'Miles',
    // loyaltyProgramRegistrationUrl: '/',
    privacyPolicy,
    multiInsurance: true,
    specialDocumentForInsurance: false,
    facebook: '',
    instagram: '',
    vk: 'https://vk.com/severstalavia',
    twitter: '',
    baggage: localeLinkFactory('/info/pravila/bagazh-i-ruchnaya-klad/#normy-provoza-bagazha'),
    meal: localeLinkFactory('/info/pravila/pravila-perevozki.php'),
    seat: localeLinkFactory('/info/dop-uslugi/vibor-mesta.php'),
    legal: localeLinkFactory('/info/pravila/pravila-perevozki.php'),
    webskySearchOrder: `https://web-checkin.severstal-avia.ru/websky/#/search-order/`,
    insurance: localeLinkFactory('/info/dop-uslugi/dobrovolnoe-strahovanie.php'),
    loyaltyProgramAccountUrl: '',
    exarePrivacyPolicyURL: privacyPolicy,
    exareRulesURL,
    baggageAnimalsURL: localeLinkFactory('/info/pravila/domashnie-zhivotnye.php'),
    loyalty: {
        links: [
            {
                title: 'Status',
                url: ``
            },
            {
                title: 'Earn miles',
                url: ``
            },
            {
                title: 'Use miles',
                url: ``
            },
            {
                title: 'FAQ',
                url: ``
            }
        ]
    }
};
export const i18n = {
    ru: {
        Checkout: {
            Miles: 'Мили',
            'I authorize the processing and storage of my personal data. I hereby confirm that if I issue air tickets/additional services to third parties, I provide personal data with their consent.': 'Я разрешаю обработку и хранение своих персональных данных. Настоящим подтверждаю, что в случае оформления мною авиабилетов/дополнительных услуг на третьих лиц, предоставляю персональные данные с их согласия.',
            'Read the consent': 'Ознакомиться с согласием'
        },
        BaggagePage: {
            'Transportation of impressions no limitations': 'Какие сумки вы берете с собой в путешествие?',
            Baggage: 'Какие сумки вы берете с собой в путешествие?'
        },
        FlightInfoModalContent: {
            Premium: 'Премиум'
        },
        Consents: {
            agreement_rules: `Я ознакомился(-лась) с {{links}}. Подтверждаю верность введенных и выбранных мной данных. Принимаю их и подтверждаю согласие на <a href="${localeLinkFactory('/info/konfidentsialnost/soglasie-pdn.php')}" target='_blank'>обработку персональных данных</a>.`,
            agreement_gdsRules: ` и правилами предоставления услуг (<a href="${companyInfo.seat}" target="_blank">выбор места<a/>, <a href="${localeLinkFactory('/info/dop-uslugi/dopolnitelnyy-bagazh.php')}">дополнительный багаж</a>)`,
            agreement_generalRules: `<span data-type="termsOfTariff">условиями тарифа</span>, <a href="${companyInfo.legal}" target="_blank">правилами перевозок</a>`,
            agreement_insurancesRules: `, <a href="${companyInfo.insurance}" target="_blank">правилами страхования</a>, а также условиями {{insurancesProductsLinks}}`,
            agreement_additionalInsuranceRules: 'публичной оферты {{link}}'
        },
        Passengers: {
            '12-23 years': '12-24 года',
            'Young peoples 12-23 years old': 'Молодежь 12-24 лет',
            'men 60+ female 55+': 'от 55 лет',
            'Adult with child': 'Мать и дитя',
            Family: 'Семейный',
            'Adults 18-100 children 0-14': 'Взрослые 18-100 дети 0-14',
            'Adults 18-100 children 0-18': 'Взрослые 18-100 дети 0-18',
            'Adults 18 years, children up to 14 years': 'Взрослые от 18 лет, дети до 14 лет',
            'Adults 18 years, children up to 18 years': 'Взрослые от 18 лет, дети до 18 лет',
            youngAdults: 'Молодежь',
            pension: 'Пенсионный'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Эл. почта / Номер карты'
        },
        SeatMap: {
            'Pay attention!': 'Обращаем ваше \n внимание!',
            'The airline reserves the right to replace the aircraft.': 'В случае замены воздушного судна, пассажирам, оплатившим услугу «Выбор места» будут предоставлены места в самолете, аналогичные по цене и комфортности с сохранением следующих параметров:',
            'In the event of an aircraft replacement, passengers who have paid for the “seat selection” service will be provided with seats on the plane that are similar in price and comfort, while maintaining the following parameters': '— Расположение места относительно окна/прохода;',
            '- Cost of services;': '— Совместная рассадка, если таковая была выбрана изначально;',
            '- Location of the place relative to the window / aisle;': '— Размещение в первом ряду салона, если таковое было выбрано изначально',
            '- Joint seating, if one was originally selected;': 'В исключительных ситуациях ООО «Авиапредприятие «Северсталь» оставляет за собой право изменения места в целях соблюдения правил безопасности полета или правил размещения на борту воздушного судна отдельных категорий пассажиров.',
            '- Placement in the front row of the cabin, if one was originally selected.': ''
        },
        Title: {
            companyName: 'Северсталь'
        },
        Exchange: {
            'Create request': 'Подтвердить обмен',
            Agreement: `Я ознакомился(-лась) с <span data-type="termsOfTariff">условиями тарифа</span> и <a href="${companyInfo.legal}" target="_blank">правилами перевозок</a>. Принимаю их и подтверждаю согласие на обработку <a href="${privacyPolicy}" target="_blank">персональных данных</a>.\nЯ согласен, что в случае наличия в первоначальном билете <a href="https://cdn.websky.aero/content/frontend/pdf/special_services_d2.pdf" target="_blank">специальной услуги</a> необходимо оформить ее заново, обратившись на сайт или в отдел бронирования Авиапредприятия. Такие услуги не переносятся на новый рейс.`
        },
        Refund: {
            'Request #{{requestId}}': '№{{requestId}}'
        },
        CheckinRules: {
            'I have read and agreed to the online check-in terms and conditions': `Я ознакомился и согласился с условиями и правилами онлайн регистрации. Согласен с правилами предоставления дополнительных услуг <a href="https://severstal-avia.ru/info/dop-uslugi/vibor-mesta.php?utm_source=web-checkin.severstal-avia.ru&utm_medium=referral&utm_campaign=web-checkin.severstal-avia.ru&utm_referrer=web-checkin.severstal-avia.ru" target="_blank">выбор места</a>, <a href="https://severstal-avia.ru/info/dop-uslugi/dopolnitelnyy-bagazh.php" target="_blank">дополнительный багаж</a> и <a href="https://severstal-avia.ru/info/dop-uslugi/dobrovolnoe-strahovanie.php">добровольное страхование</a>.`
        },
        Schedule: {
            LocalTime: 'По местному времени'
        },
        QuickSearchForm: {
            PDA_0: 'Взрослый',
            PDA_1: 'Взрослых',
            PDA_2: 'Взрослых'
        }
    },
    en: {
        Checkout: {
            'I authorize the processing and storage of my personal data. I hereby confirm that if I issue air tickets/additional services to third parties, I provide personal data with their consent.': 'I hereby confirm that if I issue air tickets/additional services to third parties, I provide personal data with their consent.',
            'Read the consent': 'Read the consent'
        },
        BaggagePage: {
            'Baggage rules': 'Show baggage requirements',
            'Transportation of impressions no limitations': 'What bags are you taking on travel?',
            Baggage: 'What bags are you taking on travel?'
        },
        Consents: {
            agreement_rules: `I have read the {{links}}. I confirm that the data I have entered and selected are correct. I accept them and confirm my consent to the <a href="${localeLinkFactory('/info/konfidentsialnost/soglasie-pdn.php')}" target="_blank">processing of personal data</a>.`,
            agreement_gdsRules: ` and rules of additional services (<a href='${companyInfo.seat}' target="_blank">seat selection</a>, <a href="${localeLinkFactory('/info/dop-uslugi/dopolnitelnyy-bagazh.php')}" target="_blank">additional baggage</a>)`,
            agreement_insurancesRules: `, <a href="${companyInfo.insurance}" target="_blank">rules of insurances</a> and {{insurancesProductsLinks}}`,
            agreement_generalRules: `<span data-type="termsOfTariff">terms of tariff</span>, <a href="${companyInfo.legal}" target="_blank">transportation regulations</a>`
        },
        Passengers: {
            '12-23 years': '12-24 years',
            'Young peoples 12-23 years old': 'Young peoples 12-24 years old',
            'men 60+ female 55+': 'Adults up 55 years',
            'Adult with child': 'Mother with child',
            Family: 'Family',
            'Adults 18-100 children 0-14': 'Adults 18-100 children 0-14',
            'Adults 18-100 children 0-18': 'Adults 18-100 children 0-18',
            'Adults 18 years, children up to 14 years': 'Adults from 18 years old, children under 14 years old',
            'Adults 18 years, children up to 18 years': 'Adults from 18 years old, children under 18 years old',
            youngAdults: 'Young people',
            pension: 'Pension'
        },
        Title: {
            companyName: 'Severstal'
        },
        LoginPage: {
            'Email / Phone / Card number': 'Email / Card number'
        },
        Schedule: {
            LocalTime: 'Local time'
        },
        QuickSearchForm: {
            PDA_0: 'Adult',
            PDA_1: 'Adults',
            PDA_2: 'Adults'
        }
    }
};
export const promoMessages = [];
export const promoLoaders = [
    {
        type: CardType.City,
        image: 'https://cdn.websky.aero/content/frontend/images/d2-banners/phone.gif'
    }
];
export const config = {
    global: {
        reserveEngines: {
            siteUrl: 'https://severstal-avia.ru/',
            checkin: 'https://web-checkin.severstal-avia.ru/web-checkin/'
        },
        showIsActualizationResultOk: false,
        companyInfo,
        i18n,
        socialAuthMethods: [{ service: OauthServiceType.Vkontakte, isAvailable: true }],
        authMethods: { phone: false, ffp: false },
        yandexMetrikaId: '22516654',
        seatMap: {
            showRecommendedSeats: true
        },
        insurance: {
            upsaleStrategy: [null],
            allowImplicitConfirm: true
        }
    },
    Account: {},
    Engine: {
        calendarDaysCount: 7,
        resultsFareSelectorInModal: false,
        showAvailableFaresOnly: true,
        promoLoaders,
        resultsDefaultSorting: Sorting.Departure
    },
    SearchForm: {
        enableComplexRoute: false,
        showPriceGraph: false,
        showPricesInDatepicker: true,
        passengersConfig
    },
    FlightStatus: {
        playMarketURL: '',
        appStoreURL: ''
    }
};
